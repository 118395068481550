<template>
    <div>
        <p
            style="
                font-size: 12pt;
                line-height: 115%;
                margin: 0pt 0pt 10pt;
                text-align: center;
            "
        >
            <span style="font-family: 宋体; font-size: 12pt; font-weight: bold"
                >宠物筹</span
            ><span style="font-family: 宋体; font-size: 12pt; font-weight: bold"
                >隐私政策</span
            >
        </p>
        <div id="main-content" class="wiki-content">
            <p class="p">
                <strong>本版发布日期：</strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >2023年</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >6</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >月</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >1</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >日</span
                    ></strong
                >
            </p>
            <p class="p">
                <strong>本版生效日期：</strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >2023年</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >6</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >月</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >1</span
                    ></strong
                ><strong
                    ><span class="15" style="color: rgb(255,0,0);"
                        >日</span
                    ></strong
                >
            </p>
            <p class="p">
                <span
                    >感谢您对宠物筹（戏游互动科技（北京）有限公司及/或关联公司，以下简称“我们”）的信任。您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，我们制定《宠物筹隐私政策》（以下简称“本隐私政策”），帮助您了解我们对个人信息的处理和保护规则。</span
                >
            </p>
            <p class="p">
                <span>本隐私政策适用于我们及我们的关联公司通过</span
                ><span>宠物筹</span
                ><span
                    >微信公众号、微信小程序、网页或者其它方式向您提供的宠物筹产品及服务。</span
                >
            </p>
            <p class="p">
                <strong
                    >需要特别说明的是，本隐私政策不适用于其他第三方向您提供的产品或服务，第三方向您提供的产品或服务应另行向您说明相关隐私政策。</strong
                >
            </p>
            <p class="p">
                <strong
                    ><span class="15"
                        >在使用宠物筹产品或服务前，请您认真阅读并透彻理解本隐私政策，特别是以粗体/下划线标识的条款，您应重点阅读，在确认并同意后再开始使用。</span
                    ></strong
                >
            </p>
            <p class="p">
                <span
                    >如您有任何疑问、意见或建议，您可以通过本隐私政策第九章的联系方式与我们联系。</span
                >
            </p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p"><strong>本隐私政策帮助您了解以下内容：</strong></p>
            <p class="p">
                <span>一、我们如何收集和使用您的个</span><span>⼈</span
                ><span>信息</span>
            </p>
            <p class="p"><span>二、我们如何使用 cookie 和同类技术</span></p>
            <p class="p">
                <span>三、我们如何存储、共享、转让、公开披露您的个人信息</span>
            </p>
            <p class="p"><span>四、消息通知及个性化推荐</span></p>
            <p class="p"><span>五、我们如何保护您的个人信息</span></p>
            <p class="p"><span>六、您的权利</span></p>
            <p class="p"><span>七、我们如何处理未成年人的个人信息</span></p>
            <p class="p"><span>八、本隐私政策如何更新</span></p>
            <p class="p"><span>九、您如何联系我们</span></p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p">
                <strong><span class="15">⼀</span></strong
                ><strong>、我们如何收集和使用您的个</strong
                ><strong><span class="15">⼈</span></strong
                ><strong>信息</strong>
            </p>
            <p class="p">
                <span
                    >个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span
                >
            </p>
            <p class="p">
                <span>宠物筹为您提供</span
                ><span
                    >求助产品及服务（如您向宠物筹发起求助登记、申请发布求助项目、筹集救助费用、您捐赠或参与求助人发布的求助项目等）过程中，将通过以下方式收集和使用您的相关个</span
                ><span>⼈</span><span>信息：</span>
            </p>
            <p class="p">
                <strong><span class="15">1、注册/登录成为用户</span></strong>
            </p>
            <p class="p">
                <span
                    >当您登录/注册成为宠物筹用户时，您可以通过提供手机号码的方式，我们将通过发送短信或语音验证码的方式来验证您的身份是否有效。验证通过后，您的账户昵称为您的手机号码，并且系统会自动为您分配头像和账户ID。您也可以通过第三方社交账户或一键登录功能直接登录，如您通过第三方社交账户如微信账户</span
                ><span>等</span
                ><span
                    >来注册登录用户，需要您同时提供并验证您的手机号码；如您通过一键登录功能直接登录的，您将提供您的当前设备手机号码。基于此，我们将会收集您的手机号码、IP地址、您通过第三方社交平台注册登录时的第三方社交账户的头像、昵称和第三方平台的UID，我们收集这些信息是为了帮助您完成宠物筹账号注册，保护您的账号安全。其中，收集手机号码是为了验证您的身份，满足相关法律法规的网络实名制要求。如果您拒绝提供手机号码或第三方社交账号，您可能无法正常注册宠物筹账户，亦无法正常发起求助项目。在您</span
                ><span>注册登录您的账号后，您可以在“</span><span>个人中心</span
                ><span
                    >”—“设置”中修改或补充您的头像、昵称、第三方账户绑定相关信息，这些信息均属于您的“账户信息”。</span
                ><strong
                    ><span class="15"
                        >若您补充这些信息将有助于我们为您提供产品/服务推荐，保证您的账户安全；</span
                    ></strong
                ><span
                    >如果您不提供这些信息，可能无法使用部分功能，但不会影响您正常浏览宠物筹的其他信息和功能。</span
                >
            </p>
            <p class="p">
                <strong><span class="15">2</span></strong
                ><strong>、发起求助项目</strong>
            </p>
            <p class="p">
                <span
                    >在您通过宠物筹平台发起求助项目时，我们将通过如下方式收集发起人、以及收款人的个人信息，以及在使用我们的产品及服务时您主动提供或产生的信息，用以向您提供服务、帮助您筹款并保障您的账户安全：</span
                >
            </p>
            <p class="p">
                <span>（1）当您发起求助项目时，您需要提供</span
                ><strong>筹款目标金额、发起人的手机号码、宠物</strong
                ><strong>或流浪动物</strong><strong>所患疾病</strong
                ><span>、</span><strong>筹款标题、筹款说明</strong
                ><span>（</span
                ><strong
                    >疾病名称、确诊日期、就诊医院、已花费金额、患病宠物医疗材料照片（如诊断证明、病案首页、住院证明、检查报告）、患病宠物生活照片。</strong
                ><span
                    >收集这些信息是为了帮助您成功发起项目。以上信息是根据相关政策以及我们帮助您顺利发起个人求助项目所必须的信息，如您拒绝提供前述信息，您发起的个人求助项目将无法通过我们平台的审核。</span
                >
            </p>
            <p class="p">
                <span>（2）</span
                ><strong
                    >若收款人为患病宠物饲养人或者管理人，则需提供收款人的姓名、身份证号码、手机号码、收款人手持身份证照片、银行卡信息（开户银行、开户姓名、银行卡号）；若收款人为医院或组织</strong
                ><strong>机构</strong
                ><strong
                    >，则需提供开户单位名称（单位名称）、对公收款账号</strong
                ><strong>、</strong><strong>社会组织法人登记证书</strong
                ><strong
                    >。其中，身份证信息、银行信息为个人敏感信息，收集这些信息是为了帮助您成功提取筹款。</strong
                ><span
                    >以上信息是根据相关政策，以及我们帮助您顺利提取筹款所必须的信息，如您拒绝提供前述信息，您将无法成功取得筹款。</span
                >
            </p>
            <p class="p">
                <strong><span class="15">3</span></strong
                ><strong>、个人捐赠服务</strong>
            </p>
            <p class="p">
                <span
                    >若您决定捐赠求助项目，那么您支持的项目活动需要您提供以下信息：</span
                >
            </p>
            <p class="p">
                <span
                    >（1）如您通过微信公众号或第三方渠道（如快手平台）捐赠的，您需要授权提供您的</span
                ><strong>微信头像、昵称或第三方渠道账号头像、昵称</strong
                ><span>；</span>
            </p>
            <p class="p">
                <span
                    >（2）如您通过前述渠道捐赠的，您需要提供您的手机号码。若您在捐赠或参与求助项目时，还需要完成注册成为我们的用户，否则您可能无法进行捐赠或参与此类项目。</span
                ><strong
                    >收集这些信息是为了保障您捐赠人的权益，当您救助的项目出现违规问题时，我们可能通过您留下的联系方式向您协调相关情况。</strong
                >
            </p>
            <p class="p">
                <span>在您捐赠求助项目后，我们将收集您的</span
                ><strong
                    ><span class="15"
                        >微信昵称、微信头像、手机号码、微信订单信息（订单号、交易时间）、微信id，</span
                    ></strong
                ><span
                    >我们会在相关筹款界面循环播报您的赠与信息（包括用户名、头像和赠与数额），并向登录宠物筹平台的您的微信好友展示您的赠与情况。如您不希望您的赠与情况被公开，您可以选择匿名赠与。</span
                >
            </p>
            <p class="p">
                <strong><span class="15">4</span></strong
                ><strong><span class="15">、项目举报/质疑</span></strong>
            </p>
            <p class="p">
                <span
                    >若您对筹款项目产生质疑，您可以进行举报。为减少恶意举报事件的发生，您需要进行实名认证后进行举报。您需提供您的</span
                ><strong>真实姓名、身份证号码、举报理由并上传举报图片</strong
                ><span
                    >。上述实名认证信息仅用于追究恶意举报人的民事及刑事责任使用，我们将保证举报人的个人信息安全。</span
                >
            </p>
            <p class="p">
                <strong><span class="15">5</span></strong
                ><strong>、求助项目证明</strong>
            </p>
            <p class="p">
                <span>若您需要为求助项目的真实性提供证明，我们会收集您的</span
                ><strong>姓名、身份证号码</strong
                ><span
                    >。收集此类信息是为了增加求助项目的真实性，帮助项目获取更多的信任及帮助。若您要为求助项目提供证明时，还需要完成注册成为我们的用户，否则您可能无法参与到项目中，亦无法提供证明。</span
                >
            </p>
            <p class="p">
                <span>6</span
                ><span
                    >、除此之外，为了进一步提升服务质量，并更妥善地履行您与我们之间的约定，我们还可能根据</span
                ><strong>您提供的联系方式，通过短信或您同意的其它方式</strong
                ><span
                    >，向您推荐我们、我们的关联公司及合作伙伴提供的产品及服务。如您拒绝接受上述推荐，您可以通过发送退订关键词的方式退订，</span
                ><strong
                    ><span class="15"
                        >或者致电1010-1019联系官方客服反馈您的诉求。</span
                    ></strong
                >
            </p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p">
                <strong
                    ><span class="15"
                        >二、我们如何使用 Cookie 和同类技术</span
                    ></strong
                >
            </p>
            <p class="p"><strong>（一）Cookie</strong></p>
            <p class="p">
                <span
                    >为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。</span
                >
            </p>
            <p class="p">
                <span
                    >我们不会将Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见</span
                ><a
                    href="http://AboutCookies.org"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(0,0,0);"
                            >AboutCookies.org</span
                        ></u
                    ></a
                ><span
                    >。您可以清除计算机上保存的所有
                    Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置(浏览器控制)。如需详细了解如何更改浏览器设置，请登录您使用的浏览器的官方网站。</span
                >
            </p>
            <p class="p">
                <strong
                    ><span class="15"
                        >（二）网络Beacon和同类技术的使用</span
                    ></strong
                >
            </p>
            <p class="p">
                <span
                    >除Cookie外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为“单像素”GIF文件或“网络Beacon”）。我们使用网络Beacon
                    的方式有：</span
                >
            </p>
            <p class="p">
                <span
                    >1、通过在宠物筹网站上使用网络Beacon，计算用户访问数量，并通过访问Cookie辨认注册的宠物筹用户。</span
                >
            </p>
            <p class="p">
                <span>2、通过得到的Cookies信息，为您提供个性化服务。</span>
            </p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p">
                <strong>三、我们如何存储、共享、转让、公开披露您的个</strong
                ><strong><span class="15">⼈</span></strong
                ><strong>信息</strong>
            </p>
            <p class="p"><strong>（一）存储</strong></p>
            <p class="p">
                <span
                    >1、我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。</span
                >
            </p>
            <p class="p">
                <span
                    >2、我们仅在本隐私政策所述目的的所必须期间内保存您的个人信息，法律法规另有规定或者您另行授权同意的除外。超出上述个人信息存储期限后，我们会对您的个人信息进行删除或匿名化处理。</span
                >
            </p>
            <p class="p">
                <span
                    >3、如我们停止运营宠物筹相关产品或服务，我们将及时停止继续收集您个人信息的活动，并将以公告的形式进行停止运营通知。同时，对我们存储的个人信息进行删除或者匿名化处理。</span
                >
            </p>
            <p class="p"><strong>（二）共享</strong></p>
            <p class="p">
                <span
                    >我们不会与宠物筹以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</span
                >
            </p>
            <p class="p">
                <span
                    >1、在获取您明确同意的情况下，我们会与其他方共享您的个人信息。</span
                >
            </p>
            <p class="p">
                <span
                    >2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</span
                >
            </p>
            <p class="p">
                <strong
                    ><span class="15"
                        >3、与我们的附属公司、关联公司共享：您的个人信息可能会与宠物筹的附属公司、关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。附属公司、关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</span
                    ></strong
                >
            </p>
            <p class="p">
                <strong
                    ><span class="15"
                        >4、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span
                    ></strong
                >
            </p>
            <p class="p">
                <strong>目前，我们的授权合作伙伴包括以下类型：</strong>
            </p>
            <p class="p">
                <span
                    >（1）提供技术、咨询服务的服务提供商。我们可能会将您的个人信息共享给支持我们提供服务的第三方服务提供商。这些服务提供商包括为我们提供基础设施技术服务、数据处理</span
                ><span>等</span
                ><span
                    >机构。但我们要求这些服务提供商只能出于为我们提供服务的目的使用您的信息，而不得出于其自身利益使用您的信息。</span
                >
            </p>
            <p class="p">
                <span
                    >（2）广告、分析服务类的授权合作伙伴。除非获取您的明确授权同意，否则我们不会与第三方广告商、分析服务提供商或其他合作伙伴共享您的个人信息。我们可能会向这些合作伙伴提供汇总后的信息、匿名化后的信息或者其它不会识别到您的个人身份的信息，但不会分享您的任何身份信息、账号信息或其他可识别信息。</span
                >
            </p>
            <p class="p">
                <span>（3）</span
                ><span
                    >为了使您能够使用本隐私政策项下的各项服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。我们会不时对该等合作方和服务提供方进行技术检测和行为审计，并要求其遵循合法法律协议，以最大限度的保证其能依法、依约、依规收集和使用数据。</span
                >
            </p>
            <p class="p">
                <strong
                    ><span class="15"
                        >第三方SDK类型、SDK名称、业务场景、第三方SDK《隐私政策》链接、其通过宠物筹收集或获取的您的信息的范围及使用目的具体如下：</span
                    ></strong
                ><span>&nbsp;</span>
            </p>
            <p class="19"><span>SDK名称：微信开放平台SDK</span></p>
            <p class="19">
                <span>使用目的：支持用户通过微信登录和内容分享</span>
            </p>
            <p class="19"><span>使用场景：登录、分享、互联</span></p>
            <p class="19">
                <span
                    >个人信息类型：设备标识符（Android 如IMEI、Android
                    ID）、MAC地址、分享图片或内容、日志信息、位置信息、其他相关信息</span
                >
            </p>
            <p class="19">
                <span>合作方主体：深圳市腾讯计算机系统有限公司</span>
            </p>
            <p class="19"><span>收集方式：SDK采集</span></p>
            <p class="19">
                <span>SDK服务提供商隐私政策：</span
                ><a
                    href="https://privacy.qq.com/policy/tencent-privacypolicy"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(0,0,0);"
                            >https://privacy.qq.com/policy/tencent-privacypolicy</span
                        ></u
                    ></a
                >
            </p>
            <p class="p"><strong>（三）转让</strong></p>
            <p class="p">
                <span>我们不会将您的个</span><span>⼈</span
                ><span>信息转让给任何公司、组织和个人，但以下情况除外：</span>
            </p>
            <p class="p">
                <span
                    >1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span
                >
            </p>
            <p class="p">
                <span
                    >2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span
                >
            </p>
            <p class="p"><strong>（四）公开披露</strong></p>
            <p class="19">
                <span>为保障筹款项目的公信力，我们会公示以下信息：（1）</span
                ><span>宠物或流浪动物</span><span>所患疾病名称、</span
                ><span>宠物或流浪动物的照片、</span
                ><span>收款人姓名、收款人</span><span>收款方式</span
                ><span
                    >、相关医疗证明材料以及发起人提交的筹款项目详情（项目详情内可能涉及到您的个人信息）；（2）收款人姓名；（3）证实人姓名、头像。除此以外，</span
                ><strong
                    ><span class="15"
                        >我们仅会在以下情况下，公开披露您的个人信息：</span
                    ></strong
                >
            </p>
            <p class="p"><span>1、获得您明确同意后；</span></p>
            <p class="p">
                <span
                    >2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span
                >
            </p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p"><strong>四、消息通知及个性化推荐</strong></p>
            <p class="p"><span class="15">1.&nbsp;消息通知</span></p>
            <p class="p" style="margin-left: 22.5pt;">
                <span
                    >您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式，我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研、争议处理等用途；此外，我们也可能会向前述过程中收集的手机号码以短信、电话的方式，为您提供您可能感兴趣的服务、功能或活动等商业性信息的用途，但请您放心，如您不愿接受这些信息，</span
                ><strong
                    >您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。</strong
                >
            </p>
            <p class="p"><span>2.个性化推荐及推送</span></p>
            <p class="p"><span>（1）推荐及推送的形式</span></p>
            <p class="p" style="margin-left: 22.5pt;">
                <span
                    >为了帮助您尽快完成筹款项目发起，在您同意接收相关业务推送后，我们会在筹款的各必要环节向您发送通知。</span
                >
            </p>
            <p class="p" style="margin-left: 22.5pt;">
                <span
                    >为了向你展示、推荐相关性更高的信息和内容，我们会通过短信、电话、微信公众号消息的形式向您推荐个性化服务。</span
                >
            </p>
            <p class="p"><span>（2）个性化推荐机制</span></p>
            <p class="18" style="margin-left: 22.5pt;">
                <span
                    >为了向您展示、推荐相关性更高的信息和内容，提供更契合您要求的服务，宠物筹会为您提供个性化推荐服务，以下我们将向您详细说明该个性化产品服务的运行机制以及您实现控制的方式。</span
                >
            </p>
            <p class="18" style="margin-left: 22.5pt;">
                <span
                    >我们提供个性化推荐服务的范围包括展示图文和/或视频形式的内容、提供搜索结果、推荐商品及广告等。</span
                >
            </p>
            <p class="18" style="margin-left: 22.5pt;">
                <span
                    >我们通过本隐私政策、弹窗的方式，告知您用于个性化推荐所收集的用户信息、用户反馈、控制信息推荐的方式等。</span
                >
            </p>
            <p class="18" style="margin-left: 22.5pt;">
                <span>我们可能会利用</span><span class="16">cookie</span
                ><span
                    >和同类技术了解您的偏好和使用习惯，进行数据分析，以改善产品服务、推荐用户感兴趣的信息或功能。</span
                >
            </p>
            <p class="18" style="margin-left: 22.5pt;">
                <span>为了预测您的偏好，我们会</span
                ><strong
                    ><span class="15"
                        >收集并使用您在宠物筹页面内的点击、分享等浏览使用行为、浏览时长、设备信息和位置信息</span
                    ></strong
                ><span
                    >，以实现上述个性化推荐的分析和计算，系统会根据计算结果自动筛选出您可能更感兴趣的内容进行推送。我们会根据您使用产品过程中的浏览行为，对推荐模型进行反馈，不断调整优化推荐结果。如果您希望不再接收个性化内容推荐及定向推送，您可以致电10101019，联系客服人员反馈您的诉求，我们将做相应处理，确保您不再接收到个性化广告推荐及定向消息推送。</span
                >
            </p>
            <p class="p" style="margin-left: 22.5pt;"><span>&nbsp;</span></p>
            <p class="p"><strong>五、我们如何保护您的个人信息</strong></p>
            <p class="p">
                <span
                    >（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，网络服务采取了传输层安全协议等加密技术，通过https方式提供浏览服务，确保用户数据在传输过程中的安全。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，确保只有授权人员才可访问个人信息，避免数据被违规使用；我们采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span
                >
            </p>
            <p class="p">
                <span
                    >（二）我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。我们通过信息接触者监控和审计机制来对数据进行全面安全控制。</span
                >
            </p>
            <p class="p">
                <span
                    >（三）我们通过了公安部安全等级保护三级认证。我们建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。</span
                >
            </p>
            <p class="p">
                <span
                    >（四）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span
                >
            </p>
            <p class="p">
                <span
                    >（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</span
                >
            </p>
            <p class="p">
                <span
                    >（六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非法授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将尽可能修复，同时启动应急措施保障您的利益。</span
                >
            </p>
            <p class="p">
                <span
                    >（七）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span
                >
            </p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p"><strong>六、您的权利</strong></p>
            <p class="p">
                <span
                    >按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span
                >
            </p>
            <p class="p"><strong>（一）访问您的个人信息</strong></p>
            <p class="p">
                <span
                    >您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</span
                >
            </p>
            <p class="p">
                <span
                    >小程序端：进入“我”点击昵称进入“宠物筹”，即可访问或编辑您账户中的个人资料信息和退出登录您的账户等。</span
                >
            </p>
            <p class="p">
                <span
                    >公众号端：进入“个人中心”点击“设置”，即可访问或编辑您账户中的个人资料信息、实名认证和退出登录您的账户等。</span
                >
            </p>
            <p class="p">
                <span
                    >对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行</span
                ><span style="color: rgb(51,51,0);"
                    >使数据访问权，您可随时通过发送电子邮件至service@</span
                ><a
                    href="http://qingsongchou.com"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(51,51,0);"
                            >qingsongchou.com</span
                        ></u
                    ></a
                ><span style="color: rgb(51,51,0);"
                    >或拨打客服电话10101019的方式向我们提出申请。</span
                >
            </p>
            <p class="p">
                <strong
                    ><span class="15" style="color: rgb(51,51,0);"
                        >（二）更正您的个人信息</span
                    ></strong
                >
            </p>
            <p class="p">
                <span style="color: rgb(51,51,0);"
                    >当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。</span
                >
            </p>
            <p class="p">
                <span style="color: rgb(51,51,0);"
                    >您可以通过“（一）访问您的个人信息”中罗列的方式自行更正。如果您无法通过上述方式更正这些个</span
                ><span style="color: rgb(51,51,0);">⼈</span
                ><span style="color: rgb(51,51,0);"
                    >信息或权限不足时，您可以随时联系我们的在线客服，或发送电子邮件至service@</span
                ><a
                    href="http://qingsongchou.com"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(51,51,0);"
                            >qingsongchou.com</span
                        ></u
                    ></a
                ><span style="color: rgb(51,51,0);"
                    >。我们将在十五个工作日内回复您的更正请求。</span
                >
            </p>
            <p class="p"><strong>（三）删除您的个人信息</strong></p>
            <p class="p">
                <span>在以下情形中，您可以向我们提出删除个人信息的请求：</span>
            </p>
            <p class="p">
                <span>1、如果我们处理个人信息的行为违反法律法规；</span>
            </p>
            <p class="p">
                <span
                    >2、如果我们收集、使用您的个人信息，却未征得您的同意；</span
                >
            </p>
            <p class="p">
                <span>3、如果我们处理个人信息的行为违反了与您的约定；</span>
            </p>
            <p class="p">
                <span>4、如果您不再使用我们的产品或服务，或您注销了账号；</span>
            </p>
            <p class="p"><span>5、如果我们不再为您提供产品或服务。</span></p>
            <p class="p">
                <span>您可以通过发送电子邮件</span
                ><span style="color: rgb(51,51,0);">至service@</span
                ><a
                    href="http://qingsongchou.com"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(51,51,0);"
                            >qingsongchou.com</span
                        ></u
                    ></a
                ><span style="color: rgb(51,51,0);">或</span
                ><span
                    >拨打客服电话10101019的方式向我们提出删除申请，我们将在收到申请且验证您的用户身份后15个工作日内回复您。</span
                >
            </p>
            <p class="p">
                <span
                    >若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</span
                >
            </p>
            <p class="p">
                <span
                    >当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span
                >
            </p>
            <p class="p">
                <strong>（四）改变您授权同意的范围或撤回您的授权</strong>
            </p>
            <p class="p">
                <span
                    >每个业务功能需要一些基本的个人信息才能完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</span
                >
            </p>
            <p class="p"><span>您可以通过以下方式自行操作：</span></p>
            <p class="p">
                <span>1</span><span>、当您使用微信</span><span>⼩</span
                ><span>程序时，您可以在</span><span>⼩</span
                ><span>程序界面中右上角“···”中选择“设置”后管理</span
                ><span>⼩</span
                ><span
                    >程序获得的位置信息、用户信息的权限，对于摄像头/相册、麦克风等权限，您可以通过手机操作系统中的权限设置撤回对微信应用权限的授权来撤回对小程序的授权。</span
                >
            </p>
            <p class="p">
                <span>2</span
                ><span
                    >、您可以通过注销账号的方式，撤回我们继续收集您个人信息的全部权限。请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或者授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或者授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</span
                >
            </p>
            <p class="p"><strong>（五）注销账户</strong></p>
            <p class="p">
                <span
                    >1、关于注销账户，若您决定不再使用我们的服务并希望注销宠物筹</span
                ><span>账户的。您</span><span>可通过</span
                ><span style="color: rgb(51,51,0);">向service@</span
                ><a
                    href="http://qingsongchou.com"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(51,51,0);"
                            >qingsongchou.com</span
                        ></u
                    ></a
                ><span style="color: rgb(51,51,0);">发</span
                ><span
                    >送电子邮件申请或通过拨打客服电话10101019的方式向我们提出，如果符合注销要求，我们在收到您的申请后会在十五个工作日内操作账户注销，您的账号注销后我们会对您的个人信息进行删除或匿名化处理。</span
                >
            </p>
            <p class="p">
                <span
                    >2、您理解，根据《求助服务协议》，发起人和求助人应及时在宠物筹平台更新</span
                ><span>宠物救助</span
                ><span
                    >的信息资料和筹款项目进展并向社会公开，接受社会监督。若宠物筹发现您存在任何虚假、伪造、隐瞒，以及挪用款项等违规行为的，宠物筹有权要求全额退还捐赠款项，情节严重的将追究相应法律责任。</span
                >
            </p>
            <p class="p">
                <span
                    >因此，您理解并同意，在以下情况下宠物筹有权拒绝您的账号注销申请：</span
                >
            </p>
            <p class="p"><span>（1）您正在使用宠物筹产品或服务的；</span></p>
            <p class="p"><span>（2）您与我们现阶段存在任何纠纷的；</span></p>
            <p class="p">
                <span
                    >（3）其他依据相关法律法规规定或我们服务的性质无法注销或不宜注销的情形的。</span
                >
            </p>
            <p class="p">
                <span
                    >如您选择注销您的账户，一般情况您可通过“注销账户”功能自行完成操作。如注销账户遇到问题，您可联系客服沟通处理。在沟通中，您可能需要提供书面材料、或以其他方式证明您的用户身份，以供我们进行验证，确保注销的准确性。</span
                >
            </p>
            <p class="p">
                <span
                    >3、在您的注销申请通过后，我们将为您注销账号。账号一旦注销将不可恢复，请您在操作之前自行备份本账号相关的所有信息及数据，确认与本账号相关的所有服务均已进行妥善处理。账号注销成功后，您将无法再使用本账号，包括但不限于：</span
                >
            </p>
            <p class="p">
                <span>（1）</span
                ><span
                    >您将无法登录、使用被注销的账号。但您已注销账户的手机号可以再次重新注册/登录，但会生成全新的账号；</span
                >
            </p>
            <p class="p">
                <span
                    >（2）您本账号的个人资料和历史信息（包括但不限于用户名、头像、身份信息、家庭成员信息、订单信息等）都将无法查看、无法找回。</span
                >
            </p>
            <p class="p">
                <span
                    >（3）您将无法继续使用宠物筹及轻松集团旗下其他相关产品或服务，但不会影响您无需登录账号即可使用的功能和服务；</span
                >
            </p>
            <p class="p">
                <span
                    >（4）您通过本账号进行登录的宠物筹旗下的产品及服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的账号余额、积分、会员权益等视为您自行放弃，将无法继续使用。您理解并同意，您的账号注销后我们将无法恢复您的前述服务，请您在提交注销申请前，务必先了解您须解绑的其他账号信息，具体可通过本政策第十一章的联系方式与我们联系。</span
                >
            </p>
            <p class="p">
                <span
                    >4、您知晓并同意，根据先关法律法规要求及宠物筹筹款公示公信要求，您注销账号前在宠物筹平台发布的信息，包括但不限于筹款记录、赠与记录、留言记录等，仍将在平台公示，以确保筹款项目的公信力。注销账号并不代表注销前的账号行为和相关责任得到豁免或减轻。</span
                >
            </p>
            <p class="p">
                <strong>（六）</strong><strong>约束信息系统自动决策</strong>
            </p>
            <p class="p">
                <strong
                    >在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</strong
                >
            </p>
            <p class="p"><strong>（七）响应您的上述请求</strong></p>
            <p class="p">
                <span
                    >为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。在以下情形中，按照法律法规要求，我们将无法响应您的请求：</span
                >
            </p>
            <p class="p">
                <span>1、与我们履行法律法规规定的义务相关的；</span>
            </p>
            <p class="p"><span>2、与国家安全、国防安全直接相关的；</span></p>
            <p class="p">
                <span>3、与公共安全、公共卫生、重大公共利益直接相关的；</span>
            </p>
            <p class="p">
                <span>4、与刑事侦查、起诉、审判和判决执行等直接相关的；</span>
            </p>
            <p class="p">
                <span>5、有充分证据表明您存在主观恶意或滥用权利的；</span>
            </p>
            <p class="p">
                <span
                    >6、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
                >
            </p>
            <p class="p">
                <span
                    >7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</span
                >
            </p>
            <p class="p"><span>8、涉及商业秘密的。</span></p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p"><strong>七、我们如何处理未成年人的个人信息</strong></p>
            <p class="p">
                <span
                    >我们的产品和服务主要面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。</span
                >
            </p>
            <p class="p">
                <strong
                    >对于经父母同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护</strong
                ><strong><span class="15">⼈</span></strong
                ><strong
                    >明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</strong
                >
            </p>
            <p class="p">
                <strong
                    >若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有疑问时，您可以通过本隐私政策第</strong
                ><strong
                    ><span class="15" style="color: rgb(51,51,0);"
                        >九</span
                    ></strong
                ><strong>节的联系方式与我们联系。</strong>
            </p>
            <p class="p">
                <strong
                    >如果我们发现我们在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会在法律要求的情况下尽快删除相关数据。</strong
                >
            </p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p"><strong>八、本隐私政策如何更新</strong></p>
            <p class="p">
                <span
                    >我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子消息发送通知，说明隐私政策的具体变更内容）。本隐私政策所指的重大变更包括但不限于：</span
                >
            </p>
            <p class="p">
                <span
                    >1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span
                >
            </p>
            <p class="p">
                <span
                    >2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span
                >
            </p>
            <p class="p">
                <span>3、个人信息共享、转让或公开披露的主要对象发生变化；</span>
            </p>
            <p class="p">
                <span
                    >4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</span
                >
            </p>
            <p class="p">
                <span
                    >5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span
                >
            </p>
            <p class="p">
                <span>6、个人信息安全影响评估报告表明存在高风险时。</span>
            </p>
            <p class="p">
                <span>我们还会将本隐私政策的旧版本存档，供您查阅。</span>
            </p>
            <p class="p"><span>&nbsp;</span></p>
            <p class="p"><strong>九、您如何联系我们</strong></p>
            <p class="p">
                <span
                    >如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</span
                >
            </p>
            <p class="p">
                <span>1、电子邮件：</span
                ><a
                    href="mailto:service@qingsongchou.com"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(0,0,255);"
                            >service@qingsongchou.com</span
                        ></u
                    ></a
                >
            </p>
            <p class="p"><span>2、电 话：010-64002900</span></p>
            <p class="p">
                <span>3、注册地址或常用办公地址：</span><span>北京市</span
                ><span>东城区安定门东大街28号2号楼7层701室</span>
            </p>
            <p class="p">
                <span
                    >我们设立了个人信息保护专职部门，您可以通过以下方式与其联系：</span
                ><span style="color: rgb(51,51,0);">&nbsp;</span
                ><a
                    href="mailto:is@qingsongchou.com"
                    class="external-link"
                    rel="nofollow"
                    ><u
                        ><span class="17" style="color: rgb(51,51,0);"
                            >is@qingsongchou.com</span
                        ></u
                    ></a
                ><span
                    >。一般情况下，我们将在十五天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案，例如：网信、电信、公安、市监及民政等监管部门进行投诉或举报。</span
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
