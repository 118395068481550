<template>
    <div>
        <p style="background-color: #ffffff; margin: 0pt; text-align: center">
            <span style="font-family: 宋体; font-size: 12pt">用户注册协议</span>
        </p>
        <div id="main-content" class="wiki-content">
            <p class="19">
                您（以下可简称“用户”，包括与宠物及流浪动物求助项目相关的发起人、求助宠物所有人，求助流浪动物救助人、证实人、收款人、捐赠人以及接受本协议约束的其他注册用户）在使用宠物筹平台（以下可简称“平台”）提供的服务前，请您务必仔细阅读《宠物筹用户注册协议》（以下可简称“本协议”）中的全部条款，特别是以加粗字体标示的部分。
            </p>
            <p class="19">
                <strong
                    >如果您继续使用平台的服务，便视为您接受了本协议的全部条款，如果您不同意本协议的全部条款，请立刻停止访问、使用平台提供的服务。</strong
                >
            </p>
            <p class="19">
                <strong>发起人、</strong
                ><strong>求助宠物所有人，求助流浪动物救助人、</strong
                ><strong>在发起</strong><strong>宠物及流浪动物</strong
                ><strong
                    >求助项目之前，应仔细阅读相关协议、公告、各类规则、操作流程的内容；捐赠人在支付赠与款项之前，应仔细阅读相关协议、公告、各类规则、操作流程、项目页面的承诺。发起人、</strong
                ><strong>求助宠物所有人，求助流浪动物救助人</strong
                ><strong
                    >、捐赠人、证实人、收款人如不同意前述内容，有权选择不使用平台服务及各项功能。一旦选择使用平台服务，即视为该发起人、</strong
                ><strong>求助宠物所有人，求助流浪动物救助人、</strong
                ><strong
                    >捐赠人、证实人、收款人已确知并完全同意相关协议、公告、规则、操作流程和项目页面的承诺。</strong
                >
            </p>
            <p class="18">&nbsp;</p>
            <p class="17">宠物筹用户注册协议</p>
            <p class="19"><br />一、平台的简介</p>
            <p class="19">
                宠物筹平台是戏游互动科技(北京)有限公司旗下的为宠物及流浪动物众筹服务平台，宠物筹平台包括但不限于宠物筹微信公众号、宠物筹微信小程序等，平台为有宠物及流浪动物众筹需求的自然人、法人及其他组织提供信息发布、需求对接、协助资金划转等相关信息服务。
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">
                二、账户的使用<br />(一)&nbsp;您可通过填写手机号、验证码或通过微信等第三方账号授权登录平台，并在登录后对头像、账户昵称等账户信息进行修改。
            </p>
            <p class="19">
                (二)&nbsp;您有义务妥善保管宠物筹账户，并对您账户项下发生的所有行为负责，自行承担一切使用该账户的言语、行为等而直接或者间接导致的法律责任。因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担，与平台无关。
            </p>
            <p class="19">
                (三)&nbsp;您同意在注册时向平台提供您的相关信息，包括但不限于您的姓名、联系电话等。并保证信息的真实性、完整性和及时性。因信息不真实、不完整或信息变更后未及时通知平台，导致的一切后果，由您自行承担，与平台无关。
            </p>
            <p class="19">
                (四)&nbsp;若您通过第三方账户登录平台，或使用平台提供的第三方服务，除本协议外，您还应遵守第三方平台的相关规定，因第三方平台导致您遭受损失的，与平台无关。
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">
                三、平台的权利与义务<br />(一)&nbsp;您允许平台依据法律法规、自律公约、用户协议采取必要的措施自己或委托第三方对您进行实名认证、资格审核和监督，并对您提供的信息真实性进行审查，对求助项目是否符合发起条件做出独立的判断，决定是否继续提供平台服务。您如果未提交信息并公示必要信息，平台有权驳回用户申请；如您存在违约违法行为（如提交信息真实性存在问题），平台可根据具体情况保留是否追究您的责任的权利。
            </p>
            <p class="19">
                (二)&nbsp;您了解平台有权拒绝不符合法律法规及平台要求的用户进行注册或取消已注册用户的用户资格，或视情况对用户暂停部分或全部服务、或注销用户账户，因此产生的后果由用户自行承担，与平台无关。
            </p>
            <p class="19">
                (三)&nbsp;为向您提供更丰富的服务，您允许平台使用您提供的所有信息资料。平台承诺尽最大努力采取合理的措施保护您的个人信息，不向任何第三方披露，除非：
            </p>
            <p class="19">1. 依据法律法规的规定应当提供；</p>
            <p class="19">2. 行政、司法机关要求平台提供；</p>
            <p class="19">3. 您授权平台向第三方提供；</p>
            <p class="19">
                4. 您在平台上完成发起、参与或资助项目后需向相关方提供的。
            </p>
            <p class="19">
                (四)&nbsp;若您通过平台发布的涉嫌违法或涉嫌侵犯他人合法权利的信息或资料，或提供虚假身份信息，或提供变造、伪造等不真实、不合法的材料，平台有权依据其独立判断对该信息或资料随时进行修改、屏蔽和删除，而无需事先通知。若您因上述行为给平台造成损失的，您还应赔偿平台的全部损失。
            </p>
            <p class="19">
                <strong>(五)</strong><strong>&nbsp;</strong
                ><strong
                    >您同意如本协议因为任何原因终止，对于您账号中的全部数据或您因使用平台服务而存储在本平台服务器中的数据等任何信息，平台可根据法律法规的要求将该等信息保留或删除，包括服务终止前您尚未完成的数据，对此您表示理解。</strong
                >
            </p>
            <p class="19">
                (六)&nbsp;<strong
                    >您理解鉴于网络服务的特殊性，平台有权在无需通知您的情况下根据平台的整体情况或相关规范、规则等，随时变更、中止或终止部分或全部的服务。</strong
                >
            </p>
            <p class="19">
                (七)&nbsp;<strong>如</strong><strong>宠物或流浪动物</strong
                ><strong
                    >求助项目不符合本协议约定，或被实名举报或投诉，平台有权采取各种方式进行核实，核实期间将暂停提供服务。经查证属实的，平台有权终止</strong
                ><strong>宠物或流浪动物</strong
                ><strong
                    >求助项目，已经筹集的赠与款项将返还捐赠人，已经提现的赠与款项，平台有权要求发起人、求助人、收款人退还，并返还捐赠人。</strong
                >
            </p>
            <p class="19">
                (八)&nbsp;如平台发现用户可能从事违法犯罪行为，如诈骗、洗钱等，或监管部门通知平台暂停或终止提供服务的，平台将暂停或终止提供服务，并采取投诉、举报、报案、诉讼等法律规定的措施。
            </p>
            <p class="19">
                (九)<strong>&nbsp;</strong>在筹款项目结束，用户申请提现时，平台有权利对筹款项目进行审核，审核通过后将筹款金额打款至收款账户。
            </p>
            <p class="19">
                (十)<strong>&nbsp;</strong>用户在本平台发起的宠物或流浪动物筹款案例不得以有偿方式（包括但不限于支付一定款项、以所筹款项分成等方式）利用第三方主体进行推广，若经举报、投诉或平台主动发现并查证属实的，平台有权终止为该发起案例进行服务，并将该案例所筹款项按照原路退回至捐款人。
            </p>
            <p class="19">
                (十一)本平台提供个人评论和跟帖评论服务，并致力使发布信息、互动交流、评论成为文明、理性、友善、高质量的意见交流。在推动发布信息、互动交流、评论业务发展的同时，不断加强相应的信息安全管理能力，完善发布信息、互动交流、评论自律，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗，履行互联网相关法律法规告知义务，开展文明上网教育。
            </p>
            <p class="19">
                平台会依据法律法规等相关规定，对您发布的个人评论、跟帖评论、互动交流等信息进行内容审核，同时结合其他用户举报提供的信息，对相关内容进行再次审查。如您发布的信息包含法律法规及国家有关规定禁止的内容，平台有权立即采取相应处理措施，包含但不限于内容屏蔽、账号封禁等措施。如您对被处置的信息内容存在异议，可以向本平台提出申诉，本平台会按照用户服务协议进行核查处理。如用户认为上述方法无法解决遇到的问题，或用户觉得有必要向司法行政机关寻求帮助的，用户可尽快向相关机关反馈，本平台将依法配合司法机关的调查取证工作。
            </p>
            <p class="18">&nbsp;</p>
            <p class="18">&nbsp;</p>
            <p class="19">
                四、您的权利和义务<br />(一)&nbsp;<strong
                    >您声明并承诺系具有完全民事行为能力的自然人，或为依据中国法律设立并有效存续的法人或其他形式的组织，具有一切必要的权利和能力订立、履行本协议下的任何义务和责任。</strong
                >
            </p>
            <p class="19">
                (二)&nbsp;您有权随时登录平台。您在平台许可的权限内有权使用发布信息、对接需求、资金划转等平台提供的相关服务，具体的服务功能以平台最终实现的功能为准。
            </p>
            <p class="19">
                (三)&nbsp;您应自行承担在使用平台服务期间，通过平台及平台关联账户划转的款项、充值款和钱包余额可能会出现货币贬值、汇率波动及收益损失等风险。您的划转款项、充值款和钱包余额（含被冻结、止付或受限制的款项）产生的利息将用于因平台提供求助服务产生的相关费用，如平台运营费用、联系用户的通讯费用、履行本协议所产生的费用及其他平台因提供求助服务产生的合理费用等。
            </p>
            <p class="19">
                (四)&nbsp;您理解在平台交易支付、充值、提现等资金流转可能会产生第三方银行或支付机构的手续费、服务费等，您应按照相关法律法规和第三方银行或支付机构的要求自行承担此类费用。
            </p>
            <p class="19">
                (五)&nbsp;<strong
                    >您承诺并保证在平台上浏览、发布、参与项目的决定是依据自身判断所作出的独立、审慎的决定，因您使用平台服务产生的风险由您自行承担，并保证依法、依协议的约定履行相应的义务。</strong
                >
            </p>
            <p class="19">
                (六)&nbsp;<strong
                    >因互联网的不可控等自身属性，您将承担使用平台服务，下载或通过平台服务取得的信息和资料，承担系统受损、资料和信息丢失以及其他相关风险。</strong
                >
            </p>
            <p class="19">
                (七)&nbsp;您保证使用平台提供的服务时将遵守国家、地方法律法规、遵从行业惯例和社会公共道德。不得制作、复制、发布、传播含有下列内容的信息：
            </p>
            <p class="19">1. 反对宪法所确定的基本原则的；</p>
            <p class="19">
                2. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            </p>
            <p class="19">3. 损害国家荣誉和利益的；</p>
            <p class="19">4. 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p class="19">5. 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
            <p class="19">6. 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
            <p class="19">
                7. 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            </p>
            <p class="19">8. 侮辱或者诽谤他人，侵害他人合法权益的；</p>
            <p class="19">
                9.
                含有虚假、有害、胁迫、侵害他人隐私、骚扰、中伤、粗俗、猥亵、或其他道德上令人反感的内容或违反公序良俗的；
            </p>
            <p class="19">
                10. 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
            </p>
            <p class="19">11. 以非法民间组织名义活动的；</p>
            <p class="19">
                12. 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
            </p>
            <p class="19">13. 侵犯他人隐私、个人信息的；</p>
            <p class="19">14. 含有虚假信息的；</p>
            <p class="19">15. 含有法律、行政法规禁止的其他内容的。</p>
            <p class="19">
                (八)&nbsp;投诉举报。您可通过宠物筹微信公众号“个人中心-咨询客服”向我们进行投诉举报，我们将在15个工作日内及时响应处理。
            </p>
            <p class="19">
                (九)&nbsp;<strong>若您是发起人、</strong
                ><strong>求助宠物所有人，求助流浪动物救助人</strong
                ><strong>，您确认并承诺：当发起人与</strong
                ><strong>求助宠物所有人，求助流浪动物救助人、</strong
                ><strong>不是同一人时，发起人作为</strong
                ><strong>求助宠物所有人，求助流浪动物救助人</strong
                ><strong
                    >的监护人、其他近亲属或受托人（受托人应当向平台提交求助人签署的授权委托书或其他证明文件），有权以发起人的身份代为</strong
                ><strong>求助宠物所有人，求助流浪动物救助人</strong
                ><strong>发起求助</strong><strong>，</strong
                ><strong>履行与</strong
                ><strong>求助宠物所有人，求助流浪动物救助人、</strong
                ><strong>相同的义务，承担与</strong
                ><strong>求助宠物所有人，求助流浪动物救助人、</strong
                ><strong>相同的责任。发起人、</strong
                ><strong>求助宠物所有人，求助流浪动物救助人</strong
                ><strong>，</strong
                ><strong
                    >公布求助信息、提交资料、提现、使用赠与款项等行为，表明</strong
                ><strong>求助宠物所有人，求助流浪动物救助人</strong
                ><strong
                    >已知晓、确认或事后知晓、追加确认发起人为其发起求助的事实，并将遵守本协议各项约定，与发起人共同向捐赠人及</strong
                ><strong>/</strong
                ><strong>或平台承担责任。捐赠人确认已知晓发起人为</strong
                ><strong>求助宠物所有人，求助流浪动物救助人</strong
                ><strong>发起求助的实际情况，且对此无异议。</strong>
            </p>
            <p class="19">
                (十)&nbsp;您按规定完成实名认证后，可以发布个人评论及跟帖评论。您产生的个人评论及跟帖评论内容，应当遵守法律法规，遵循公序良俗，弘扬社会主义核心价值观，不得发布法律法规和国家有关规定禁止的信息内容，否则公司有权立即采取相应处理措施。你同意并承诺不制作、复制、发布、传播上述禁止的信息内容。
            </p>
            <p class="19">
                您可通过宠物筹微信公众号“个人中心-咨询客服”向我们进行投诉、举报各类违法违规行为、违法传播活动、违法有害信息等内容，平台将及时受理和处理你的投诉举报，以共同营造风清气正的网络空间。同时，任何组织或个人如发现违反本规定行为的，也可以向网信部门投诉举报。
            </p>
            <p class="19">
                <strong
                    >若您违反本协议或其他服务条款的行为，平台有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除内容或评论、短期禁止发布内容或评论、限制帐号部分或者全部功能直至终止提供服务、永久关闭帐号等措施，对于因此而造成你无法正常使用帐号及相关服务、无法正常获取你帐号内资产或其他权益等后果，平台不承担任何责任。本平台有权公告处理结果，且有权根据实际情况决定是否恢复相关帐号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，平台将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容平台有权不予恢复。</strong
                >
            </p>
            <p class="18">&nbsp;</p>
            <p class="18">&nbsp;</p>
            <p class="19">
                五、平台的通知<br />您允许平台会不定期的向您预留或提供的联系方式或以站内信的方式发送通知消息、短信等。如因您注销账户、取消关注等情形而无法接收通知的，由您自行承担不利后果。因此请您不要轻易注销账户、取消关注。
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">
                六、知识产权归属<br />(一)&nbsp;您了解平台展示的所有数据库、网站设计、文字和图表、软件、照片、录像、音乐、声音及其前述组合，软件编译、相关源代码和软件（包括小应用程序和脚本）的知识产权均归平台所有。
            </p>
            <p class="19">
                (二)&nbsp;您了解平台提供的服务具有独家、排他性质，若您决定使用平台提供的服务，即视为您在平台发表的任何形式的作品的著作权，包括复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利转让给平台所有，平台可不限期限、不限地区使用，且您自愿放弃获取报酬的权利。
            </p>
            <p class="19">
                (三)&nbsp;您不得对本平台或者本服务运行过程中释放到任何终端内存中的数据、服务运行过程中的交互数据，以及本平台运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非本平台经授权的第三方工具/服务接入本平台和相关系统。
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">七、协议的修改</p>
            <p class="19">
                <strong
                    >您了解互联网的特殊属性，平台保留在任何时间自行修改、增删本协议中任何条款的权利。平台在修改相关内容后，会及时在平台上进行公告或直接替换协议版本，若您继续使用平台提供的服务，则视为您接受修改后的协议内容。</strong
                >
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">
                八、不可抗力及免责条款<br />(一)&nbsp;由于发生地震、台风、水灾、火灾等人力不能预见、不能避免、不能克服的不可抗力事件，致使协议无法履行或不能按约定履行，遭遇不可抗力的一方应于不可抗力发生之日起3个工作日内以书面形式通知对方，并于发生后10个工作日内出具有关部门的证明文件。因不可抗力造成的损失，双方互不承担责任。
            </p>
            <p class="19">
                (二)&nbsp;由于计算机和互联网的特殊性，平台服务过程中可能出现技术性中断、服务延时及安全性问题等(包括但不限于平台系统崩溃或无法正常使用、电信设备故障、网络故障、电力中断、恶意攻击、计算机病毒、服务中断导致的数据丢失)。您应了解此类风险，若您因此无法使用平台部分或全部服务，平台不承担任何责任。
            </p>
            <p class="19">
                (三)&nbsp;为维护、升级平台服务，平台需要定期或不定期地进行停机维护，平台会在维护前进行公告，维护期间可能将无法使用部分或全部平台服务，对此平台不承担任何责任。
            </p>
            <p class="19">
                (四)&nbsp;您理解并同意，同大多数互联网服务一样，平台提供的服务可能会受多种因素影响，比如用户原因、网络服务质量、社会环境等，也可能会受各种安全问题的侵扰，比如他人非法窃取利用用户资料，进行现实中的骚扰、用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您的终端设备信息和数据安全，继而影响本平台的正常使用等。因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。
            </p>
            <p class="19">
                (五)&nbsp;维护软件安全与正常使用是平台和您的共同责任，平台将按照行业标准合理审慎地采取必要技术措施保护您的终端设备信息和数据安全，但您了解本平台并不能就此提供完全保证。
            </p>
            <p class="19">
                (六)&nbsp;您了解平台仅为互联网信息服务的提供者，并非广告活动的运营主体，如提供相关商品与服务的主体所发布的信息被工商行政管理部门界定为广告，且存在欺骗、误导消费者或者其他违反《中华人民共和国广告法》、《互联网广告管理暂行办法》等法律法规的行为，由所涉广告的广告主、经营者和发布者承担相应责任，本平台不承担任何责任。
            </p>
            <p class="19">
                (七)&nbsp;您了解并知悉宠物及流浪动物求助信息不属于慈善公开募捐信息，信息的真实性由发起人、求助宠物所有人，求助流浪动物救助人自行负责。
            </p>
            <p class="19">
                (八)&nbsp;有关宠物及流浪动物求助的相关国家法律法规及政策变化，平台可能根据该变化依法调整宠物及流浪动物求助服务的内容，平台不承担任何责任。
            </p>
            <p class="19">
                (九)&nbsp;平台仅为宠物及流浪动物求助项目提供平台网络技术服务。求助宠物所有人，求助流浪动物救助人与捐赠人之间订立附义务的赠与合同关系。平台作为居间方，并不是赠与合同关系中的任何一方。使用平台服务产生的全部法律后果由发起人、求助宠物所有人，求助流浪动物救助人与捐赠人自行承担。平台未对宠物及流浪动物求助项目作任何形式的担保。
            </p>
            <p class="19">
                (十)&nbsp;本平台依据本协议约定获得处理违法违规内容的权利，该权利不构成平台的义务或承诺，平台不能保证及时发现违法行为或进行相应处理。
            </p>
            <p class="19">
                (十一)您理解并同意，本协议旨在保障遵守国家法律法规、维护公序良俗，保护用户和他人合法权益，平台在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证本平台判断完全与司法机关、行政机关的判断一致，如因此产生的后果你已经理解并同意自行承担。
            </p>
            <p class="19">
                (十二)因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担全部可能由此引起的法律责任。因您的违法、侵权或违约等行为导致平台及其关联方、控制公司、继承公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿平台及其关联方、控制公司、继承公司因此遭受的全部损失。
            </p>
            <p class="19">
                (十三)平台尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用宠物筹平台及相关服务时上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，平台有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行处理并承担全部可能由此引起的法律责任；如因您的侵权行为导致本平台及其关联方、控制公司、继承公司遭受损失的（包括经济、商誉等损失），您还应足额赔偿平台及其关联方、控制公司、继承公司遭受的全部损失。
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">
                九、违约责任<br />(一)&nbsp;若您违反本协议的任何条款，无论是否给平台造成损失，平台均有权要求您立即整改，并按照平台要求履行相关义务，给平台造成损失的，您应当承担赔偿责任。
            </p>
            <p class="19">
                (二)&nbsp;平台对于违反信息发布规范或不适用平台服务规范的用户，有权删除违规内容、停止提供服务、终止求助项目、要求退还赠与款项，公示违规行为人的信息。如给平台或第三方造成损失的，平台有权要求行为人承担赔礼道歉、赔偿损失等责任。
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">
                十、隐私保护<br />平台十分重视您隐私的保护。就关于您的个人信息，我们将严格按照国家法律法规的要求，制定《隐私政策》对您进行保护和规范，详情请参阅《隐私政策》。
            </p>
            <p class="18">&nbsp;</p>
            <p class="19">
                十一、其他<br />(一)&nbsp;<strong
                    >若本协议履行过程中发生争议，双方本着公平公正的态度友好协商解决。协商不成时，任何一方均可向北京市东城区人民法院提起诉讼。</strong
                >
            </p>
            <p class="19">
                (二)&nbsp;本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。
            </p>
            <p class="19">
                (三)&nbsp;本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
            </p>
            <p class="19">
                (四)&nbsp;本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
            </p>
            <p class="19">
                (五)&nbsp;如果您对本协议或本服务有意见或建议，可向平台客服留言，我们会给予您必要的帮助。
            </p>
            <p class="19">
                (六)&nbsp;本协议内容与平台救助服务的其他声明、须知、提示等构成统一整体，同时包括平台可能不断发布的关于本服务的相关协议、业务规则等内容共同构成平台救助服务的整体协议。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。
            </p>
            <p class="19">
                (七)&nbsp;平台享有自行修改本协议中任何内容的权利。修改后的协议内容及相关规则、通知等成为本协议的必要组成部分。平台将本协议的修改内容通过平台予以公布。用户在公布后继续使用平台服务的，视为确认接受修改后的协议及相关规则、通知等的约束。
            </p>
            <p>&nbsp;</p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
