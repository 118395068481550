<template>
    <div>
        <div id="main-content" class="wiki-content">
            <p class="p">
                您在使用宠物筹平台（以下可简称“平台”）发起、参与或支持宠物及流浪动物求助项目前，请您务必仔细阅读《宠物及流浪动物求助服务协议》（以下可简称“本协议”）中的全部条款，特别是以加粗字体标示的部分。<strong
                    >如果您继续使用平台的服务，便视为您接受了本协议的全部条款，如果您不同意本协议的全部条款，请立刻停止访问、使用平台提供的服务。</strong
                >
            </p>
            <br>
            <h3 id="id-宠物筹求助服务协议-宠物筹宠物及流浪动物求助服务协议">
                <strong>宠物筹</strong><strong>宠物及流浪动物</strong
                ><strong>求助服务协议</strong>
            </h3>
            <br>
            <h3 id="id-宠物筹求助服务协议-一、协议的范围">
                <strong>一、协议的范围</strong>
            </h3>
            <p class="p">
                本协议是您与宠物筹平台之间，就您使用平台发起、参与或支持宠物及流浪动物求助项目所订立的协议。
            </p>
            <h3 id="id-宠物筹求助服务协议-二、平台服务">
                <strong>二、平台服务</strong>
            </h3>
            <p class="p">
                宠物筹平台为求助的宠物所有人及流浪动物救助人无偿提供求助信息发布等技术服务，向用户提供支付通道、投诉受理等与宠物及流浪动物求助相关的服务。
            </p>
            <h3 id="id-宠物筹求助服务协议-三、目标金额">
                <strong>三、目标金额</strong>
            </h3>
            <p class="p">
                目标金额指发起人通过平台发起宠物及流浪动物求助项目时填写的所筹款项数额。平台有权根据求助宠物及流浪动物的情况设置相应的目标金额上限。
            </p>
            <h3 id="id-宠物筹求助服务协议-四、宠物及流浪动物求助项目的发起">
                <strong>四、</strong><strong>宠物及流浪动物</strong
                ><strong>求助项目的发起</strong>
            </h3>
            <p class="p">
                （一）您应按照平台要求提供真实、详尽及合法的求助宠物及流浪动物的相关信息和发起人个人信息，包括但不限于发起人、宠物所有人、流浪动物救助人身份信息、宠物及流浪动物诊疗信息、宠物所有人、流浪动物救助人财产情况等证明信息。<strong
                    >平台有权要求您提供平台认为必须审核的信息材料。</strong
                >
            </p>
            <p class="p">
                （二）您应按照平台要求提供并公开包括但不限于以下信息材料：
            </p>
            <p>1.&nbsp;求助宠物、流浪动物的疾病情况；</p>
            <p>2.&nbsp;治疗花费情况；</p>
            <p>3.&nbsp;宠物所有人、流浪动物救助人的经济情况；</p>
            <p>4.&nbsp;预期款项用途；</p>
            <p>5.&nbsp;其他可提供的信息</p>
            <p class="p">
                对于以上信息，您可以自行提供证明材料，也可以邀请第三方协助佐证。
            </p>
            <p class="p">
                如您对上述任一信息材料选择不公示的，您了解可能会对您的筹款项目产生不利影响，且平台有权根据您提供并公示的信息材料决定您的筹款项目能否发起。
            </p>
            <p class="p">
                （三）您应实时更新求助宠物或流浪动物的救助情况和项目进展，符合及时、详尽、准确的要求后向社会公开。
            </p>
            <p class="p">
                （四）您须对所提交的文字描述、图片等资料保证完整和真实，无任何虚构以及隐瞒真相的情形，您不得冒用他人资料（包括不限于姓名、图片、公司机构名称、昵称等）如果您提供的资料不完整、不真实或不合法，或者冒用他人资料，平台有权直接修改资料或者结束您使用平台各项服务的权利，并追究您的法律责任。若您非求助宠物所有人或者流浪动物救助人，您应取得求助宠物所有人或者流浪动物救助人合法授权，遵照求助宠物所有人或者流浪动物救助人意志完成发起，否则平台将结束您使用各项服务的权利。涉及刑事犯罪的，平台将移交相关资料至公安机关。
            </p>
            <p class="p">
                （五）在平台发起筹款的发起人须为求助宠物所有人、流浪动物救助人或宠物所有人、流浪动物救助人的监护人，特殊情况下宠物所有人、流浪动物救助人本人，宠物所有人、流浪动物救助人的监护人可委托第三人代为发起，但须依法完成书面授权，且该第三人、项目发起人和宠物所有人、流浪动物救助人应承担连带责任。
            </p>
            <p class="p">
                （六）您发布、上传的内容包括图片、动物病历、动物诊断结果和相关费用明细等信息材料，应有合法来源，相关内容为您所有或您已获得权利人的授权。您同意平台为履行本协议或提供本服务的目的而使用您发布、上传的所有信息材料。
            </p>
            <p class="p">
                （七）若平台收到权利人通知，主张您发送或上传的内容侵犯其相关权利的，本平台有权进行独立判断并采取删除、屏蔽或断开链接等措施，若您有相反证据证明您是合法权利人，您应及时提供给平台，否则您应承担给权利人及平台造成的所有损失。
            </p>
            <p class="p">
                （八）您应确保自己是拥有完全民事行为能力和民事权利能力的中华人民共和国公民，或法人组织、社会团体，按照平台规则递交申请，经平台审核通过后，按照相关要求发起个宠物及流浪动物求助项目。
            </p>
            <p class="p">
                （九）您应按照平台展示页面的要求和规则，发起相关项目。
            </p>
            <p class="p">
                （十）<strong
                    >您提交求助项目申请后，平台工作人员可能会要求您提供相关材料加以证明，您了解，平台对您申请的项目是否拥有上线发布资格，具有最终决定权。</strong
                >
            </p>
            <p class="p">
                （十一）您必须按照规定的用途使用您筹集到的款项，不得将款项用于和求助项目无关的事项，否则平台有权要求您返还筹集到的所有款项，并追究您的法律责任。
            </p>
            <p class="p">
                （十二）您必须保证上传、填写的求助信息等情况等真实、合法、及时、准确，不存在编造、捏造等情形，若一旦发现您提供的信息为虚假信息，平台有权要求您返还已提现的全部款项，并追求您的违约责任。
            </p>
            <p class="p">
                （十三）平台有权根据求助项目情况设置相应的求助金额上限，您应按照求助项目的实际需求填写求助金额，救助宠物或者流浪动物后应上传相关发票证明款项使用情况。
            </p>
            <h3 id="id-宠物筹求助服务协议-五、求助项目的捐赠">
                <strong>五、求助项目的捐赠</strong>
            </h3>
            <p class="p">
                （一）您理解通过平台发布的任何信息，平台并不能保证其完全真实或完全准确，您应进行理性分析、判断后决定是否捐赠、资助。
            </p>
            <p class="p">
                <strong
                    >（二）您同意使用微信支付、支付宝等支付平台的服务进行捐赠或资助活动。您发出支付指令后，微信支付或支付宝等将从您的银行账户或支付账户中扣划相应款项至平台账户，在对求助事项审核结束后，平台将根据审核结果进行资金划转操作，审核通过的，平台将资金转账至求助人或发起人指定的收款账户。</strong
                >
            </p>
            <p class="p">
                （三）您了解您捐赠或资助的求助项目是无偿的，可能无法给您以实物、金钱、债券、股权等方式作为回报。
            </p>
            <p class="p">
                （四）<strong
                    >若您误捐、错误操作，须撤回捐赠行为，您应及时向平台申请退回您的款项，若发起人已完成提现的，平台将无法帮助你退回款项。</strong
                >
            </p>
            <p class="p">
                （五）<strong
                    >如经查实求助人、发起人发布的信息虚假、所筹款项未用于治疗或指定目的的，或其它经平台确认为发起人项目存在违法、违约情形的，您同意并授权平台追究发起人的相应法律责任。</strong
                >
            </p>
            <h3 id="id-宠物筹求助服务协议-六、求助项目规范">
                <strong>六</strong><strong>、求助项目规范</strong>
            </h3>
            <p class="p">
                （一）平台非常重视对未成年人的保护。若您是18周岁以下的未成年人，在使用平台的服务前，应事先取得您家长或法定监护人的同意。
            </p>
            <p class="p">
                （二）您理解平台的某些功能可能会让第三方知晓您的信息，例如：公开的个人资料、发起或参与项目的相关行为信息等。
            </p>
            <p class="p">
                （三）求助项目筹集的款项，只能由求助宠物所有人、流浪动物救助人本人或其监护人提现，求助人为非自然人的只能提现至非自然人对公账户，或提现至所救助的宠物、流浪动物所在医疗机构账户。
            </p>
            <p class="p">
                （四）项目发起筹款后，申请提现前平台仍会审核项目内容和收款人信息，审核未通过前不得提现。
            </p>
            <p class="p">
                （五）<strong
                    >求助项目违反协议和平台要求，被实名举报或投诉的，平台将立即启动投诉处理机制，并根据调查审核情况对该项目进行处理。若经平台审查并证实举报确真实无误的，将按投诉处理机制对项目进行下线处理，项目上线期间筹集的款项将原路返还捐赠人，涉嫌违法的，还将进一步向有关机关检举。</strong
                >
            </p>
            <p class="p">
                （六）求助项目上线期间，求助的宠物、流浪动物死亡或求助项目已无法实现或失去实现意义的，筹集资金将原路返回给捐赠人。
            </p>
            <p class="p">
                <strong>（</strong><strong>七</strong
                ><strong>）您了解平台会不</strong><strong>定期</strong
                ><strong
                    >修改求助项目发起和捐赠的规则和服务，若您继续使用平台服务，视为您接受平台的新规则。</strong
                >
            </p>
            <p class="p">
                （八）您了解，若您发起求助项目后，无论是否申请成功，在您向平台提供联系方式后，平台可能会不时的给您发送各种通知、求助信息等消息。
            </p>
            <p class="p">
                （九）您清楚的知道，因使用第三方支付产生的成本，由您自行承担，具体收费标准届时以第三方支付机构的规则为准。
            </p>
            <p class="p">
                （十）若您完成提现后，有剩余款项未使用完，您有义务将剩余款项退还给赠与人。
            </p>
            <h3 id="id-宠物筹求助服务协议-七、违约处理">
                <strong>七</strong><strong>、违约处理</strong>
            </h3>
            <p class="p">
                因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担全部的法律责任。本平台因此遭受损失的，您还应向平台承担赔偿责任（包括但不限于诉讼费、律师费、公告费、平台预期可得利益、平台间接或直接的损失）
            </p>
            <h3 id="id-宠物筹求助服务协议-八、其他">
                <strong>八</strong><strong>、其他</strong>
            </h3>
            <p class="p">
                （一）<strong
                    >您使用本服务即视为您已阅读并同意受本协议的约束。本平台有权在必要时修改本协议条款。您可以在本软件的最新版本中查阅相关协议条款。本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务。</strong
                >
            </p>
            <p class="p">
                <strong
                    >（二）本协议签订地为中华人民共和国北京市东城区。</strong
                >
            </p>
            <p class="p">
                <strong
                    >（三）若您和本平台之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地人民法院管辖。</strong
                >
            </p>
            <p class="p">
                （四）<strong
                    >本协议内容与平台求助服务的其他声明、须知、提示等构成统一整体，同时包括平台可能不断发布的关于本服务的相关协议、业务规则等内容共同构成平台求助服务的整体协议。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。</strong
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
